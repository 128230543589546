import React, { useMemo } from 'react';
import { inject, observer } from 'mobx-react'
import compose from 'recompose/compose'
import { List, Skeleton } from 'antd';

import { useAsyncCall } from '../../gx-web-ui/utils/hook';

const NearBy = ({
  mapStore,
  coordinate,
  type
}) => {

  const {
    loading,
    result: data,
    error
  } = useAsyncCall(() => mapStore.getNearby(coordinate, type), [coordinate]);

  const avatar = useMemo(() => {
    switch (type) {
      case 'train_station':
        return (
          <span class="material-symbols-outlined">train</span>
        )
      case 'shopping_mall':
        return (
          <span class="material-symbols-outlined">local_mall</span>
        )
      default:
        return null
    }
  }, [type])

  const content = useMemo(() => {
    if (!data) {
      return null
    }

    return (
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={item => (
          <List.Item>
            <Skeleton title={false} loading={loading}>
              <List.Item.Meta
                avatar={avatar}
                title={item.name}
              />
              <div>{item.distance > 1000 ? `${(item.distance / 1000).toFixed(2)} km` : `${item.distance} m`}</div>
            </Skeleton>
          </List.Item>
        )}
      />
    )
  }, [data])

  return (content)
}

export default compose(
  inject('mapStore'),
  observer
)(NearBy)