import React from "react"
import PropTypes from "prop-types"
import { Layout, Row, Space } from "antd"
import { LinkedinOutlined, FacebookOutlined, InstagramOutlined, YoutubeOutlined } from "@ant-design/icons";
import Typography from '@material-ui/core/Typography';
import { compose } from 'recompose'
import { inject, observer } from 'mobx-react'
import { hBox } from "gx-web-ui/utils/flex";
import MePanel from '../components/setting/MePanel';
import LandingPageOptions from "./LandingPageOptions";

import "./LightContainer.less"
import ExploreHeader from "./layout/ExploreHeader";

const { Footer, Content, Sider } = Layout

const LightContainer = (props) => {

    const { children, noWrapper = false } = props

    return (
        <Layout>
            <ExploreHeader />
            {
                noWrapper
                    ? children
                    : <Layout className="main-content">
                        <Content className={"fluid-container"}>
                            {children}
                        </Content>
                    </Layout>
            }
            <LandingPageOptions />
            <Footer style={{ ...hBox('center', 'center'), background: '#0D0E10', width: "100%", padding: 14 }}>
                <Row className="fluid-container" justify="space-between" align="middle">
                    <Typography style={{ 'color': 'white', margin: 0 }} variant="body2" >© {new Date().getFullYear()} All rights reserved Teamlink</Typography>

                    <Row>
                        <Space size={20}>
                            <a style={{ color: '#fff' }} href="https://www.linkedin.com/company/teamlink-com-au/" target="_blank"><LinkedinOutlined /></a>
                            <a style={{ color: '#fff' }} href="https://www.facebook.com/Teamlinkcom-111821207947941" target="_blank"><FacebookOutlined /></a>
                            <a style={{ color: '#fff' }} href="https://www.instagram.com/teamlink.au/" target="_blank"><InstagramOutlined /></a>
                            <a style={{ color: '#fff' }} href="https://www.youtube.com/channel/UCThD0icL7m2-kxcAz_1mVEw" target="_blank"><YoutubeOutlined /></a>
                        </Space>
                    </Row>
                </Row>
            </Footer>
            <MePanel />
        </Layout>
    )
}
LightContainer.propTypes = {
    children: PropTypes.node.isRequired,
    noWrapper: PropTypes.bool
}


export default LightContainer
