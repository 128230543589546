import React,{useState} from 'react'
import {motion} from 'framer-motion' 
import { ButtonBase } from '@material-ui/core'
import { useTheme,makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { hBox,vBox } from "gx-web-ui/utils/flex";
import {ReactComponent as Next} from '@src/assets/svgIcons/Next 1.svg'
import { typography } from '../../theme/theme';

const containerVariants = {
  open:{
    height:'100%'
  },
  closed:(custom)=>({
    height:custom
  })
}

const iconVariants = {
  open:{
    rotate:270,
  },
  closed:{
    rotate:90
  }
}

const coverVariants ={
  open:{
    opacity:0,
  },
  closed:{
    opacity:1
  }
}

const ReadMoreContainer = (props) => {
  const {children, defaultHeight} = props
  const styles = useStyles()
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  return (
    <motion.div
      animate={open?'open':'closed'}
      initial={'closed'}
    >
      {defaultHeight&&<motion.div custom={defaultHeight} variants={containerVariants} className={styles.container}>
        {children}
        <motion.div variants={coverVariants} className={styles.cover}/>
      </motion.div>}
      <ButtonBase disableRipple className={styles.btn} onClick={()=>setOpen(!open)}>
        <Typography className={styles.btnText}>Read {open?'less':`more`}</Typography>
        <motion.span variants={iconVariants} className={styles.alignH}>
          <Next height={16} width={16} fill={theme.palette.clientPrimary.main}/>
        </motion.span>
      </ButtonBase>
    </motion.div>
  )
}

const useStyles = makeStyles(theme=>({
  btn:{
    ...hBox('center'),
    marginTop:16,
  },
  btnText:{
    color:theme.palette.clientPrimary.main,
    marginRight:8,
    ...typography.subtitle2,
    fontWeight:600
  },
  alignH:{
    ...hBox('center')
  },
  container:{
    overflow:'hidden',
    position:'relative',
  },
  cover:{
    position:'absolute',
    top:0,
    right:0,
    bottom:0,
    left:0,
    background: `linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0) 43%)`
  }
}))

export default ReadMoreContainer
