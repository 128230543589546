import React, { useMemo } from 'react';
import { inject, observer } from 'mobx-react'
import compose from 'recompose/compose'
import { Tabs, List, Typography, Skeleton, Empty } from 'antd';

import { useAsyncCall } from '../../gx-web-ui/utils/hook';

const { TabPane } = Tabs;

const SchoolInformation = ({
  mapStore,
  coordinate
}) => {

  const {
    loading,
    result: schoolInfo,
    error
  } = useAsyncCall(() => mapStore.getSchoolInfo_(coordinate), [coordinate]);

  const tabs = useMemo(() => {
    if (!schoolInfo) {
      return <Empty />
    }

    return Object.keys(schoolInfo).map(key => (
      <TabPane tab={key} key={key}>
        <List
          itemLayout="horizontal"
          dataSource={schoolInfo?.[key] || []}
          renderItem={item => (
            <List.Item>
              <Skeleton title={false} loading={loading}>
                <List.Item.Meta
                  title={item.name}
                  description={item.rank}
                />
                <div>{item.distance > 1000 ? `${(item.distance / 1000).toFixed(2)} km` : `${item.distance} m`}</div>
              </Skeleton>
            </List.Item>
          )}
        />
      </TabPane>
    ))
  }, [schoolInfo])

  return (
    <Tabs defaultActiveKey="All">
      {tabs}
    </Tabs>
  )
}

export default compose(
  inject('mapStore'),
  observer
)(SchoolInformation)