import React from 'react'
import { Button } from 'antd'
import GoogleMapReact from 'google-map-react'
import settings from '../../../src/config/app'

import styles from './MapAreaHighlight.module.less'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'

/**
 * Draw and hightlight predefined polygons on map
 * See reference - https://developers.google.com/maps/documentation/javascript/examples/polygon-simple
 * 
 * @param {polygon} - [
    { lat: 25.774, lng: -80.19 },
    { lat: 18.466, lng: -66.118 },
    { lat: 32.321, lng: -64.757 },
    { lat: 25.774, lng: -80.19 },
  ]
 * @param {defaultProps} - {
    zoom: 5,
    center: { lat: 24.886, lng: -70.268 },
  }
 * @returns 
 */
const MapAreaHighlight = ({
  polygon,
  defaultProps,
}) => {

  const mapRef = React.useRef();

  // starts drawing and highlight polygon area
  const onApiLoaded = ({ map, maps }) => {
    if (polygon.length > 0) {
      const highlightedPolygon = new maps.Polygon({
        paths: polygon,
        strokeColor: "#fa6400",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#fa6400",
        fillOpacity: 0.2,
      });
      highlightedPolygon.setMap(map)
      mapRef.current = map
    }
  }

  const zoomIn = () => {
    if (mapRef.current) {
      mapRef.current.setZoom(mapRef.current.zoom + 1)
    }
  }

  const zoomOut = () => {
    if (mapRef.current) {
      mapRef.current.setZoom(mapRef.current.zoom - 1)
    }
  }

  return (
    <div className={styles.mapContainer}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: `${settings.config.googleApiKey}&language=en&channel=4` }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={(maps) => ({
          zoomControl: false,
          fullscreenControl: false,
          mapTypeControl: false
        })}
        onGoogleApiLoaded={onApiLoaded}
        yesIWantToUseGoogleMapApiInternals={true}
      />

      <div className={styles.controlContainer}>
        <Button icon={<PlusOutlined />} onClick={zoomIn} />
        <Button icon={<MinusOutlined />} onClick={zoomOut} />
      </div>
    </div>
  )
}

export default MapAreaHighlight