import React from 'react'
import withRoot from '@src/withRoot'
import { compose } from 'recompose'
import { inject, observer } from 'mobx-react'
import { Row, Col, Button, Typography } from 'antd'
import { ArrowUpOutlined, HeartOutlined } from '@ant-design/icons'
import get from 'lodash/get'
import { Link } from "gatsby"

import styles from './SuburbProfileTemplate.module.less'
import ReadMoreContainer from '@src/components/common/ReadMoreContainer'
import LightContainer from '../LightContainer'
import MapAreaHighlight from '../map/MapAreaHighlight'
import SchoolInformation from '../map/SchoolInformation'
import NearBy from '../map/NearBy'

const SuburbProfileTemplate = ({
  pageContext: { data },
  filterStore,
  propertyStore
}) => {

  const { setCurrentFilterType, setSearchAddress } = filterStore
  const { getMapProperties_ } = propertyStore

  const polygon = get(data, 'map.geojson.coordinates[0]', []).map(item => ({
    lat: item[1],
    lng: item[0]
  }))

  const defaultProps = {
    center: {
      lat: parseFloat(get(data, 'map.lat', 0)),
      lng: parseFloat(get(data, 'map.lon', 0)),
    },
    zoom: 14
  }

  const suburb = data.locality.split(',')[0]

  const onSaleClick = () => {
    setSearchAddress(suburb)
    setCurrentFilterType("rent")
    getMapProperties_()
  }

  const onRentClick = () => {
    setSearchAddress(suburb)
    setCurrentFilterType("buyExisting")
    getMapProperties_()
  }

  return (
    <LightContainer noWrapper>
      <div className={styles.neighbourhoodTemplate}>
        <div className={styles.bannerContainer}>
          <Row className={styles.banner} style={{ backgroundImage: `url('${data.banner}')`}}>
            <Col xs={24} sm={24} md={12} className={styles.overlay}>
              <div>
                <Typography.Title level={4}>Welcome to</Typography.Title>
                <Typography.Title>{suburb}</Typography.Title>

                <Typography.Paragraph className={styles.description}>{data.description}</Typography.Paragraph>
              </div>

              <div className={styles.buttonContainer}>
                <Link to="/explore">
                  <Button size="large" onClick={onRentClick}>
                    <Typography.Text strong>For Rent</Typography.Text>
                  </Button>
                </Link>
                <Link to="/explore">
                  <Button size="large" onClick={onSaleClick}>
                    <Typography.Text strong>For Sale</Typography.Text>
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
        </div>

        <article className={styles.article}>
          <h1><HeartOutlined /> What you’ll love</h1>
          <p>{data.pointsOfInterest}</p>

          <div className={styles.mapContainer}>
            <MapAreaHighlight
              polygon={polygon}
              defaultProps={defaultProps} />
          </div>

          <ReadMoreContainer defaultHeight={280}>
            <Row gutter={30}>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Typography.Title level={4}>School Information</Typography.Title>
                <SchoolInformation coordinate={{
                  latitude: defaultProps.center.lat,
                  longitude: defaultProps.center.lng
                }} />
              </Col>
              <Col xs={24} sm={24} md={24} lg={6}>
                <Typography.Title level={4}>Transport</Typography.Title>
                <NearBy
                  type="train_station"
                  coordinate={{
                    latitude: defaultProps.center.lat,
                    longitude: defaultProps.center.lng
                  }} />
              </Col>
              <Col xs={24} sm={24} md={24} lg={6}>
                <Typography.Title level={4}>Key Places</Typography.Title>
                <NearBy
                  type="shopping_mall"
                  coordinate={{
                    latitude: defaultProps.center.lat,
                    longitude: defaultProps.center.lng
                  }} />
              </Col>
            </Row>
          </ReadMoreContainer>
          <div dangerouslySetInnerHTML={{ __html: data.article }} />

          <Button
            className={styles.backToTop}
            type="link"
            icon={<ArrowUpOutlined />}
            onClick={() => window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            })}
          >Back to top</Button>
        </article>
      </div>
    </LightContainer>
  )
}

export default compose(
  inject('filterStore', 'uiStore', 'propertyStore'),
  observer,
  withRoot
)(SuburbProfileTemplate)